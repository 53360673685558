import { LanguageEnum } from 'locale';

const multiLanguageImageMap = {
  [LanguageEnum.VI]: [
    'how-to-scan.png',
    'st-feature-colla-img-department-group.png',
    'st-feature-colla-img-employee-manage.png',
    'st-feature-colla-img-onboard.png',
    'st-feature-colla-img-org.png',
    'st-feature-colla-img-team-info.png',
    'st-feature-collaboration-img-top.png',
    'st-feature-commu-convenient-pattern-history.png',
    'st-feature-commu-convenient-pattern-status.png',
    'st-feature-commu-convenient-pattern-status_mobile.png',
    'st-feature-commu-img-combineforward.png',
    'st-feature-commu-img-noticebot-calendar.png',
    'st-feature-commu-img-noticebot-guest.png',
    'st-feature-commu-img-noticebot-warehouse.png',
    'st-feature-commu-img-recall.png',
    'st-feature-commu-img-reply.png',
    'st-feature-commu-img-translation.png',
    'st-feature-communiation-img-top.png',
    'st-feature-management-img-approval-adminportal.png',
    'st-feature-management-img-attendance-adminportal.png',
    'st-feature-management-img-attendance-mobile.png',
    'st-feature-management-img-checkin-adminportal.png',
    'st-feature-management-img-checkin-mobile.png',
    'st-feature-management-img-claim-adminportal.png',
    'st-feature-management-img-claim-mobile.png',
    'st-feature-management-img-leave-adminportal.png',
    'st-feature-management-img-leave-mobile.png',
    'st-home-img-org-approval-v2.png',
    'st-home-img-org-attendance-v2.png',
    'st-home-img-org-checkin-v2.png',
    'st-home-img-org-claim-v2.png',
    'st-home-img-org-leave-v2.png',
    'st-feature-management-img-approval-mobile.png',
  ],
};

export const getImageSrc = (src, language) => {
  const selectedImageMap = {};
  (multiLanguageImageMap[language] || []).forEach((image) => {
    selectedImageMap[image] = `/img/vi/${image}`;
  });

  return selectedImageMap[src] || `/img/${src}`;
};
