import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { initFacebookPixelPageViewTracking } from 'utils/fbq';
import { GTagTrackPageView } from 'utils/ga';
import {
  initAcquisitionData,
  FIRST_CLICK_TRACKING_KEY,
  LAST_CLICK_TRACKING_KEY,
} from 'utils/utm';
import useLocalStorage from 'hooks/useLocalStorage';

const handleRouteChange = (url, { shallow }) => {
  if (!shallow) {
    GTagTrackPageView();
  }
};

const PageViewTracking = ({ children }) => {
  const router = useRouter();

  // Persist utm params to localStorage.
  // According to business requirement, we have to keep track the first and last source where users come from
  const [firstAcquisitionData, setFirstAcquisitionData] = useLocalStorage(
    FIRST_CLICK_TRACKING_KEY,
    ''
  );
  const [, setLastAcquisitionData] = useLocalStorage(
    LAST_CLICK_TRACKING_KEY,
    ''
  );

  useEffect(() => {
    initFacebookPixelPageViewTracking();

    GTagTrackPageView();

    initAcquisitionData(
      firstAcquisitionData,
      setFirstAcquisitionData,
      setLastAcquisitionData
    );

    router.events.on('routeChangeComplete', handleRouteChange);

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events]);

  return children;
};

export default PageViewTracking;
