import { isEmptyString } from 'utils';
import { UtmCodes } from 'consts';

export const FIRST_CLICK_TRACKING_KEY = 'FIRST-CLICK-TRACKING-UTM';
export const LAST_CLICK_TRACKING_KEY = 'LAST-CLICK-TRACKING-UTM';

function getParamByName(name, url) {
  if (!url && typeof window !== 'undefined') url = window.location.href;

  name = name.replace(/[\[\]]/g, '\\$&');

  const regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`);
  const results = regex.exec(url);

  if (!results) return null;
  // If can't find param value, then return an empty string
  if (!results[2]) return '';

  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

export const initAcquisitionData = (
  firstUtmValue,
  setFirstUtmValue,
  setLastUtmValue
) => {
  const utmData = {};

  if (typeof document !== 'undefined' && !isEmptyString(document.referrer))
    utmData.campaign_source = document.referrer;

  if (!isEmptyString(getParamByName(UtmCodes.Source)))
    utmData.campaign_source = getParamByName(UtmCodes.Source);

  if (!isEmptyString(getParamByName(UtmCodes.Medium)))
    utmData.campaign_medium = getParamByName(UtmCodes.Medium);

  if (!isEmptyString(getParamByName(UtmCodes.Campaign)))
    utmData.campaign_name = getParamByName(UtmCodes.Campaign);

  if (Object.keys(utmData).length > 0) {
    if (firstUtmValue === '') setFirstUtmValue(utmData);
    else setLastUtmValue(utmData);
  }
};
