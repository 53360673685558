import { DeviceInfoContext } from 'components/shared/DeviceInfo';
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { isNodeVisible } from 'utils';

const throttleDelay = 20;

const AnimationScrollSpy = (WrappedComponent) => {
  class ScrollSpy extends PureComponent {
    state = {};

    componentDidMount() {
      this.handleScroll();
      window.addEventListener('scroll', this.handleScroll);
    }

    componentWillUnmount() {
      this.clearTimer();
      window.removeEventListener('scroll', this.handleScroll);
    }

    setRef = (n) => {
      this.node = n;
    };

    clearTimer = () => {
      if (this.handleTimer) {
        clearTimeout(this.handleTimer);
        this.handleTimer = null;
      }
    };

    handleAnimationEnter = () => {
      const { top, bottom, height } = this.node.getBoundingClientRect();
      const { isMobile } = this.context;
      const halfHeight = isMobile
        ? Math.min(150, height / 2)
        : Math.min(300, height / 2);

      const isEnter = isNodeVisible({
        top: top + halfHeight,
        bottom: bottom - halfHeight,
      });

      if (isEnter) {
        this.setState({ isEnter });
        this.clearTimer();
        window.removeEventListener('scroll', this.handleScroll);
      }
    };

    handleScroll = () => {
      this.clearTimer();
      this.handleTimer = setTimeout(this.handleAnimationEnter, throttleDelay);
    };

    render() {
      const { isEnter } = this.state;

      return (
        <WrappedComponent
          {...this.props}
          setRef={this.setRef}
          isEnter={isEnter}
        />
      );
    }
  }

  ScrollSpy.contextType = DeviceInfoContext;

  return ScrollSpy;
};

AnimationScrollSpy.propTypes = {
  setRef: PropTypes.func.isRequired,
  isEnter: PropTypes.bool,
};

export default AnimationScrollSpy;
