import { useLocaleContext } from 'components/shared/Locale';
import React, { useMemo } from 'react';
import cx from 'classnames';
import Options from 'components/shared/Options';
import popOverStyles from 'components/shared/Header/desktop/HeaderSelect.module.scss';
import styles from 'components/shared/Header/desktop/FeatureSelect.module.scss';
import headerStyles from 'components/shared/Header/index.module.scss';
import { useRouter } from 'next/router';
import { ROUTE_FULL_PATH } from 'configs';
import HeaderSelect from 'components/shared/Header/desktop/HeaderSelect';

const FeatureSelect = () => {
  const { push, pathname } = useRouter();
  const { locale } = useLocaleContext();

  const features = useMemo(
    () => [
      {
        name: locale('website_feature_a'), // communication
        value: 'communication',
        subFeatureOptions: [
          {
            value: 'message',
            option: locale('website_message'),
          },
          {
            value: 'whispers',
            option: locale('website_secure_whisper'),
          },
          {
            value: 'voice-call',
            option: locale('website_voice_call'),
          },
          {
            value: 'noti-bot',
            option: locale('website_notice_bot'),
          },
          {
            value: 'chat-experience',
            option: locale('website_chat_experience'),
          },
        ],
      },
      {
        name: locale('website_feature_b'), // team management
        value: 'team',
        subFeatureOptions: [
          {
            value: 'org-contact',
            option: locale('website_org_contacts'),
          },
          {
            value: 'team-info',
            option: locale('website_team_info'),
          },
          {
            value: 'dept-group',
            option: locale('website_department_group'),
          },
          {
            value: 'employee-manage',
            option: locale('website_employee_management'),
          },
          {
            value: 'employee-invite',
            option: locale('website_employee_invitation'),
          },
        ],
      },
      {
        name: locale('website_feature_c'), // workspace
        value: 'workspace',
        subFeatureOptions: [
          {
            value: 'leave-manage',
            option: locale('website_leave_management'),
          },
          {
            value: 'attendance-check',
            option: locale('website_attendance_check'),
          },
          {
            value: 'claim-manage',
            option: locale('website_claim_management'),
          },
          {
            value: 'approve-center',
            option: locale('website_approval_center'),
          },
          {
            value: 'check-in',
            option: locale('website_checkin'),
          },
        ],
      },
      {
        name: locale('website_feature_d'), // seatalk open platform
        value: 'open',
        subFeatureOptions: [
          {
            value: 'bot',
            option: locale('website_bot'),
          },
          {
            value: 'workspace-app',
            option: locale('website_workspace_app'),
          },
          {
            value: 'login-with-seatalk',
            option: locale('website_login_with_seatalk'),
          },
        ],
      },
    ],
    []
  );

  return (
    <HeaderSelect
      className={cx(headerStyles.navBarItem, styles.featureSelectItem)}
    >
      <div>{locale('website_header_features')}</div>
      <div className={headerStyles.navBarItemArrow} />
      <div
        className={cx(
          popOverStyles.optionWrap,
          styles.featureSelectOptionWrapper
        )}
      >
        <div className={cx(styles.featureSelectOptionWrapperInner)}>
          {features.map((feature, index) => (
            <div className={styles.optionsWrapper} key={index}>
              <div
                className={styles.groupTitle}
                onClick={() => {
                  if (
                    pathname === `${ROUTE_FULL_PATH.FEATURES}/${feature.value}`
                  ) {
                    window.location.hash = '';
                  } else {
                    push(`${ROUTE_FULL_PATH.FEATURES}/${feature.value}`);
                  }
                }}
              >
                {feature.name}
              </div>
              <Options
                options={feature.subFeatureOptions}
                handleChange={(value) => {
                  if (
                    pathname === `${ROUTE_FULL_PATH.FEATURES}/${feature.value}`
                  ) {
                    window.location.hash = value;
                  } else {
                    push(
                      `${ROUTE_FULL_PATH.FEATURES}/${feature.value}#${value}`
                    );
                  }
                }}
                wrapperStyles={{ maxHeight: 'none', border: 'none' }}
              />
            </div>
          ))}
        </div>
      </div>
    </HeaderSelect>
  );
};

export default FeatureSelect;
