import { useLocaleContext } from 'components/shared/Locale';
import React from 'react';
import { Helmet } from 'react-helmet';
import { useRouter } from 'next/router';
import { getCanonicalAttr } from 'utils';

const FeatureHead = () => {
  const { language, locale } = useLocaleContext();
  const { asPath } = useRouter();

  return (
    <Helmet
      title={locale('website_feature_page_title')}
      meta={[
        {
          name: 'description',
          content: locale('website_feature_page_description'),
        },
      ]}
      link={[getCanonicalAttr(language, asPath)]}
    />
  );
};

export default FeatureHead;
