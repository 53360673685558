import { useDeviceInfoContext } from 'components/shared/DeviceInfo';
import React, { useRef, useState } from 'react';
import { useOutsideClick } from 'hooks/useOutsideClick';
import cx from 'classnames';
import styles from 'components/shared/Header/desktop/HeaderSelect.module.scss';
import PropTypes from 'prop-types';

const HeaderSelect = ({ id, className, children }) => {
  const selectRef = useRef(null);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const { isTouchDevice } = useDeviceInfoContext();

  useOutsideClick(selectRef, () => setIsPopupOpen(false));

  return (
    <div
      id={id}
      ref={selectRef}
      className={cx(
        className,
        styles.popOver,
        // For desktop, the behavior is that option will appear when user hovers
        // For mobile, the behavior is that option will appear when user clicks
        isTouchDevice ? styles.touchDevicePopOver : '',
        isTouchDevice && isPopupOpen ? styles.touchDevicePopOverOpen : ''
      )}
      onClick={() => setIsPopupOpen(true)}
    >
      {children}
    </div>
  );
};

HeaderSelect.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node,
};

export default HeaderSelect;
