import Polyglot from 'node-polyglot';
import Cookies from 'js-cookie';
import { getParameterFromURL } from '../utils';

export const LanguageEnum = {
  EN: 'en',
  ZH: 'zh',
  ZH_TW: 'zh-tw',
  ZH_HK: 'zh-hk',
  ZH_CN: 'zh-cn',
  VI: 'vi',
  ID: 'id',
  TH: 'th',
  MY: 'my',
  PH: 'ph',
};

export const languages = [
  LanguageEnum.EN,
  LanguageEnum.ZH_TW,
  LanguageEnum.ZH_CN,
  LanguageEnum.VI,
  LanguageEnum.ID,
  LanguageEnum.TH,
];

export const languageNames = {
  [LanguageEnum.EN]: 'English',
  [LanguageEnum.ID]: 'Bahasa Indonesia',
  [LanguageEnum.VI]: 'Tiếng Việt',
  [LanguageEnum.TH]: 'ภาษาไทย',
  [LanguageEnum.ZH_TW]: '繁體中文',
  [LanguageEnum.ZH_CN]: '简体中文',
};

export const languageIds = {
  [LanguageEnum.EN]: 1,
  [LanguageEnum.ID]: 6,
  [LanguageEnum.VI]: 5,
  [LanguageEnum.TH]: 4,
  [LanguageEnum.ZH_TW]: 3,
  [LanguageEnum.ZH_CN]: 2,
  [LanguageEnum.MY]: 1,
  [LanguageEnum.PH]: 1,
};

export const E_LANGUAGE_TYPE_OTP = {
  [LanguageEnum.EN]: 0,
  [LanguageEnum.ID]: 1,
  [LanguageEnum.ZH_CN]: 4,
  [LanguageEnum.ZH_TW]: 5,
  [LanguageEnum.TH]: 8,
  [LanguageEnum.VI]: 9,
};

const translations = languages.reduce(
  (result, lang) => ({
    ...result,
    [lang]: new Polyglot({
      locale: lang,
      // eslint-disable-next-line import/no-dynamic-require,global-require
      phrases: require(`./transify/${lang}.json`),
      interpolation: { prefix: '<%=', suffix: '%>' },
    }),
  }),
  {}
);

export function restrictLanguageValue(lang) {
  if (lang === LanguageEnum.ZH) return LanguageEnum.ZH_CN;
  if (lang === LanguageEnum.ZH_HK) return LanguageEnum.ZH_TW;
  if (!languages.includes(lang)) return LanguageEnum.EN;
  return lang;
}

export function getLocaleByTld(tld) {
  switch (tld) {
    case 'tw':
      return LanguageEnum.ZH_TW;
    case 'th':
      return LanguageEnum.TH;
    case 'id':
      return LanguageEnum.ID;
    case 'vn':
      return LanguageEnum.VI;
    default:
      return LanguageEnum.EN;
  }
}

export function getServerLanguage(ctx) {
  const { req } = ctx || {};
  const { headers, cookies, url } = req || {};
  const langFromSeaTalkApp = getParameterFromURL('lang', url);
  const { host } = headers || {};
  const { seatalk_lang: seatalkLang } = cookies || {};
  const tld = host ? host.split(':')[0].split('.').pop() : undefined;

  return {
    langFromSeaTalkApp,
    langFromDomain:
      seatalkLang && languages.includes(seatalkLang)
        ? seatalkLang
        : getLocaleByTld(tld),
  };
}

export function getClientLanguage() {
  const cookieLanguage = Cookies.get('seatalk_lang');
  if (cookieLanguage) {
    return cookieLanguage;
  }

  if (typeof window !== 'undefined') {
    const { host } = window?.location || {};
    const tld = host ? host.split(':')[0].split('.').pop() : undefined;
    return getLocaleByTld(tld);
  }

  return LanguageEnum.EN;
}

export function getLanguageId() {
  return languageIds[getClientLanguage()];
}

export function updateLanguage(lang) {
  const newLanguage = restrictLanguageValue(lang);

  if (newLanguage !== Cookies.get('seatalk_lang')) {
    Cookies.set('seatalk_lang', newLanguage);
    window.location.reload();
  }
}

/**
 * Translates given key with specified options. Specify default value and/or language in options to fallback on if key does not exist for translation.
 * @param key key of string to be translated
 * @param options translation options
 */
export default function locale(
  key,
  selectedLocale = 'en',
  { _ = `<${key}>`, defaultLang, ...rest } = {}
) {
  const options = { _, ...rest };
  const translation = translations[selectedLocale];

  if (defaultLang) {
    const defaultLangResult = translations[defaultLang].t(key, options);
    return translation.t(key, { _: defaultLangResult, ...rest });
  }

  return translation.t(key, options);
}
