import { useDeviceInfoContext } from 'components/shared/DeviceInfo';
import { useLocaleContext } from 'components/shared/Locale';
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import LanguageSelect from 'components/shared/Header/desktop/LanguageSelect';
import styles from 'components/shared/Header/desktop/DesktopNav.module.scss';
import headerStyles from 'components/shared/Header/index.module.scss';
import { ROUTE_FULL_PATH } from 'configs';
import { getHelpCenterLink } from 'configs/env';
import Link from 'next/link';
import FeatureSelect from 'components/shared/Header/desktop/FeatureSelect';
import ExternalAppSelect from 'components/shared/Header/desktop/ExternalAppSelect';
import cx from 'classnames';
import { FBPixelRegistrationTracking } from 'utils/fbq';
import { GTagRegistrationTracking } from 'utils/ga';
import ExternalLink from '../../ExternalLink';

const NavLinkItem = ({ item, className, onClick }) => {
  const { href, to, text } = item;

  if (to) {
    return (
      <Link href={to} locale={false}>
        <a
          className={cx(className || headerStyles.navBarItem)}
          onClick={onClick}
          style={{ cursor: 'pointer' }}
        >
          {text}
        </a>
      </Link>
    );
  }

  return (
    <ExternalLink
      className={className || headerStyles.navBarItem}
      href={href}
      target="_blank"
      rel="noopener noreferrer"
      onClick={onClick}
    >
      {text}
    </ExternalLink>
  );
};

NavLinkItem.propTypes = {
  item: PropTypes.object,
  className: PropTypes.string,
  onClick: PropTypes.func,
};

const DesktopNav = () => {
  const { locale, language } = useLocaleContext();
  const { isMobile } = useDeviceInfoContext();

  const navLinkItems = useMemo(
    () => [
      {
        to: ROUTE_FULL_PATH.DOWNLOAD,
        text: locale('website_header_download'),
      },
      {
        href: getHelpCenterLink(language),
        text: locale('website_header_help_center'),
      },
    ],
    []
  );

  return (
    <div className={styles.desktopNavbar}>
      <div className={styles.leftNavbar}>
        <FeatureSelect />

        {navLinkItems.map((item, key) => (
          <NavLinkItem key={key} item={item} />
        ))}
      </div>

      <div className={styles.rightNavbar}>
        <LanguageSelect />

        <ExternalAppSelect />

        <NavLinkItem
          item={{
            text: locale('website_get_started'),
            to: ROUTE_FULL_PATH.START,
          }}
          className={headerStyles.getStartedBtn}
          onClick={() => {
            if (!isMobile) {
              FBPixelRegistrationTracking.enter();
              GTagRegistrationTracking.enter();
            }
          }}
        />
      </div>
    </div>
  );
};

export default DesktopNav;
