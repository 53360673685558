import { useDeviceInfoContext } from 'components/shared/DeviceInfo';
import { useEffect } from 'react';

export function useOutsideClick(ref, onClick) {
  const { isTouchDevice } = useDeviceInfoContext();

  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        onClick();
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    if (isTouchDevice) {
      document.addEventListener('touchstart', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      if (isTouchDevice) {
        document.removeEventListener('touchstart', handleClickOutside);
      }
    };
  }, [isTouchDevice, onClick, ref]);
}
