import React, { useEffect } from 'react';
import styles from 'components/shared/Toast/index.module.scss';
import cx from 'classnames';
import PropTypes from 'prop-types';

const Toast = ({ isShow, message, callbackOnToastHidden }) => {
  useEffect(() => {
    let myTimeout;

    if (isShow) {
      myTimeout = setTimeout(() => {
        callbackOnToastHidden();
      }, 2500);
    }

    return () => {
      clearTimeout(myTimeout);
    };
  }, [isShow, callbackOnToastHidden]);

  return (
    <div className={styles.errorToastContainer}>
      <div className={cx(styles.errorToast, isShow && styles.errorToastEnter)}>
        {message}
      </div>
    </div>
  );
};

Toast.propTypes = {
  isShow: PropTypes.bool.isRequired,
  message: PropTypes.string.isRequired,
  callbackOnToastHidden: PropTypes.func.isRequired,
};

export default Toast;
