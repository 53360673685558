import { useLocaleContext } from 'components/shared/Locale';
import { ROUTE_FULL_PATH } from 'configs';
import React, {
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import cx from 'classnames';
import Launch from 'components/features/Launch';
import styles from 'components/features/FeatureWrapper.module.scss';
import PropTypes from 'prop-types';
import FeatureHead from 'components/features/FeatureHead';
import { useRouter } from 'next/router';
import Link from 'next/link';

const FeatureGroup = {
  Communication: 'communication',
  Team: 'team',
  Workspace: 'workspace',
  SeatalkOpenPlatform: 'open',
};

const FeatureWrapper = ({ children }) => {
  const [isScrollDown, setIsScrollDown] = useState(false);
  const { pathname } = useRouter();
  const { locale } = useLocaleContext();

  const featureMenuListRef = useRef(null);
  const featureMenuRef = useRef({});

  const FeatureGroupTitle = useMemo(
    () => ({
      [FeatureGroup.Communication]: locale('website_feature_a'),
      [FeatureGroup.Team]: locale('website_feature_b'),
      [FeatureGroup.Workspace]: locale('website_feature_c'),
      [FeatureGroup.SeatalkOpenPlatform]: locale('website_feature_d'),
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const isFeaturePath = pathname.includes(ROUTE_FULL_PATH.FEATURES);
  const pathnameSplitted = pathname.split('/');
  const activeFeatureGroup = pathnameSplitted[pathnameSplitted.length - 1];

  useEffect(() => {
    const anchorElement = document.getElementById(
      window.location.hash.replace('#', '')
    );

    if (anchorElement) {
      window.scrollTo({
        top: anchorElement.offsetTop,
        behavior: 'smooth',
      });
    }

    const handlePageScroll = () => setIsScrollDown(!!window.pageYOffset);

    window.addEventListener('scroll', handlePageScroll);

    const handleHashChange = () => {
      const newAnchorElement = document.getElementById(
        window.location.hash.replace('#', '')
      );

      if (newAnchorElement) {
        window.scrollTo({
          top: newAnchorElement.offsetTop,
          behavior: 'smooth',
        });
      } else {
        window.scrollTo(0, 0);
      }
    };
    window.addEventListener('hashchange', handleHashChange);

    return () => {
      window.removeEventListener('scroll', handlePageScroll);
      window.removeEventListener('hashchange', handleHashChange);
    };
  }, []);

  useEffect(() => {
    if (
      featureMenuListRef?.current &&
      featureMenuRef?.current &&
      isFeaturePath
    ) {
      // Source: https://git.garena.com/shopee/mall-fe/core-fe-pl/nebula/-/blob/master/components/tabs/src/Tabs/index.tsx
      const activeTab = featureMenuRef.current[activeFeatureGroup];

      if (
        featureMenuListRef.current.scrollWidth >
        featureMenuListRef.current.offsetWidth
      ) {
        // pivot = offsetLeft if activeTab is placed right in the middle
        const pivot =
          (featureMenuListRef.current.offsetWidth - activeTab.offsetWidth) / 2;

        const offsetLeftToCentralizeTab = activeTab.offsetLeft - pivot;
        let scrollLeft = offsetLeftToCentralizeTab;

        // make sure scroll left does not exceed boundary left and right
        scrollLeft = Math.max(scrollLeft, 0);
        scrollLeft = Math.min(
          scrollLeft,
          featureMenuListRef.current.scrollWidth -
            featureMenuListRef.current.offsetWidth
        );

        featureMenuListRef.current.scrollTo({
          left: scrollLeft,
          behavior: 'instant',
        });
      }
    }
  }, [activeFeatureGroup, featureMenuListRef, featureMenuRef, isFeaturePath]);

  if (!isFeaturePath) return null;
  return (
    <>
      <FeatureHead />
      <div className={styles.wrap}>
        <div
          ref={featureMenuListRef}
          className={cx(styles.selector, isScrollDown ? styles.boxShadow : '')}
        >
          {Object.keys(FeatureGroup).map((key) => {
            const featureGroup = FeatureGroup[key];

            return (
              <Link
                key={key}
                href={`${ROUTE_FULL_PATH.FEATURES}/${featureGroup}`}
              >
                <a
                  className={cx(
                    styles.option,
                    activeFeatureGroup === featureGroup ? styles.selected : ''
                  )}
                  ref={(el) => {
                    featureMenuRef.current[featureGroup] = el;
                  }}
                >
                  {FeatureGroupTitle[featureGroup]}
                </a>
              </Link>
            );
          })}
        </div>
      </div>

      {children}
    </>
  );
};

FeatureWrapper.propTypes = {
  children: PropTypes.node,
};

export default FeatureWrapper;
