import axios from 'axios';
import { LanguageEnum } from 'locale';

function removeWwwPrefix(str) {
  let processedStr = str;

  if (processedStr.startsWith('www.')) {
    processedStr = processedStr.slice(4, str.length);
  }
  return processedStr;
}

const hostnameWithoutWWW =
  typeof window !== 'undefined'
    ? removeWwwPrefix(window.location.hostname)
    : '';
export const isLocal = process.env.ENV === 'local';
export const isTest = process.env.ENV === 'test';
export const isLive = process.env.ENV === 'live';
export const isStaging = process.env.ENV === 'staging';

export function getOaLink() {
  return isLocal
    ? 'https://oa.test.seatalk.io'
    : `https://oa.${hostnameWithoutWWW}`;
}

export function getSeatalkOpenLink() {
  const env = process.env.ENV;

  if (isLocal) {
    return 'https://open.test.seatalk.io';
  }

  if (isTest || isStaging) {
    return `https://open.${env}.seatalk.io`;
  }

  return 'https://open.seatalk.io';
}

export function getCaptchaLink() {
  if (typeof window !== 'undefined') {
    if (isLocal) {
      return {
        generateLink:
          'https://test.seatalk.sg/api/v4/anti_fraud/captcha/generate',
        verifyLink: 'https://test.seatalk.sg/api/v4/anti_fraud/captcha/verify',
        reportLink: 'https://test.seatalk.sg/api/v4/anti_fraud/captcha/report',
      };
    }

    const baseLink = window.location.origin;

    return {
      generateLink: `${baseLink}/api/v4/anti_fraud/captcha/generate`,
      verifyLink: `${baseLink}/api/v4/anti_fraud/captcha/verify`,
      reportLink: `${baseLink}/api/v4/anti_fraud/captcha/report`,
    };
  }

  return {};
}

export function getSeatalkOfficialLink() {
  if (typeof window !== 'undefined') {
    if (isLocal) {
      return 'https://test.seatalk.io';
    }

    return window.location.origin;
  }
  return 'https://seatalk.io';
}

function getHelpCenterLinkByLanguage(language) {
  const envs = /(.*)seatalk/.exec(hostnameWithoutWWW);
  const env = envs ? envs[1] : '';

  switch (language) {
    case LanguageEnum.EN:
      if (hostnameWithoutWWW.endsWith('.my'))
        return `https://help.${env}seatalk.my`;
      break;
    case LanguageEnum.VI:
      return `https://help.${env}seatalk.vn`;
    case LanguageEnum.TH:
      return `https://help.${env}seatalk.in.th`;
    case LanguageEnum.ID:
      return `https://help.${env}seatalk.co.id`;
    case LanguageEnum.ZH_TW:
      return `https://help.${env}seatalk.tw`;
    case LanguageEnum.ZH_CN:
      return `https://help.${env}seatalk.tw`;
    default:
      if (hostnameWithoutWWW.endsWith('.my'))
        return `https://help.${env}seatalk.my`;
  }

  return null;
}

export function getHelpCenterLink(language) {
  // An additional logic by language that users are using
  const helpCenterLinkByLanguage = getHelpCenterLinkByLanguage(language);
  if (helpCenterLinkByLanguage) return helpCenterLinkByLanguage;

  // added because all other help center domains other than .in.th are not ready
  // remove when other domains are ready
  if (
    !['.in.th', '.co.id', '.vn', '.tw'].some((tld) =>
      hostnameWithoutWWW.endsWith(tld)
    )
  ) {
    if (isLive) {
      return 'https://help.seatalk.io';
    }

    return isStaging
      ? 'https://help.staging.seatalk.io'
      : 'https://help.test.seatalk.io';
  }

  return hostnameWithoutWWW.startsWith('localhost')
    ? 'https://help.test.seatalk.io'
    : `https://help.${hostnameWithoutWWW}`;
}

export const getMMSLink = () => {
  let cid = 'sg';

  if (typeof window !== 'undefined') {
    const hostnameSplitted = window.location.hostname.split('.');
    const hostnameLastTld = hostnameSplitted[hostnameSplitted.length - 1];

    // List of tld taken from our sitemap (robots-and-sitemap.js)
    const localTld = ['vn', 'id', 'tw', 'th', 'ph', 'my', 'sg'];

    if (localTld.includes(hostnameLastTld)) {
      cid = hostnameLastTld;
    }
  }

  return `https://down-${cid}.img.susercontent.com`;
};

export const getUnsubscribeAuthToken =
  isLive || isStaging
    ? 'b5BHkyWi4LEmpChL8vm45aVxLizMmZ86LLOt5oRrsR7amx1956ZUl56FPDPixJpY'
    : 'CE_TEST_TOKEN';
// sosApiServer
const sosApiServerStaging = 'https://sos.staging.seatalk.sea.com';
const sosApiServerLive = 'https://sos.seatalk.sea.com';
const sosApiServerTest = 'https://sos.test.seatalk.sea.com';
const sosApiServer = (() => {
  if (isStaging) {
    return sosApiServerStaging;
  }
  return isLive ? sosApiServerLive : sosApiServerTest;
})();

export const sosAxiosInstance = axios.create({
  baseURL: sosApiServer,
});

export const oaAxiosInstance = axios.create({
  withCredentials: !isLocal,
  baseURL: `${getOaLink()}/api/official_site`,
});

// Portal Service
export const portalApiServerTest = 'https://portal.test.haiserve.com';
const portalApiServerStaging = 'https://portal.staging.haiserve.com';
const portalApiServerLive = 'https://portal.haiserve.com';
export const portalAiServer = (() => {
  if (isLive) {
    return portalApiServerLive;
  }
  if (isStaging) {
    return portalApiServerStaging;
  }
  return portalApiServerTest;
})();

export const portalAxiosInstance = axios.create({
  baseURL: portalAiServer,
});
