import { portalApiServerTest } from 'configs/env';
import { rest } from 'msw';
import { ENDPOINTS } from 'consts';
import * as login from './login';
import * as deleteAcc from './delete';

export const requestHandlers = [
  // ====================================================
  /**
   * Request OTP cases:
   */
  rest.post(
    `${portalApiServerTest}${ENDPOINTS.requestOTP}`,
    login.requestOTPSuccess
  ),
  // rest.post(
  //   `${portalApiServerTest}${ENDPOINTS.requestOTP}`,
  //   login.requestOTPInvalidParam
  // ),
  // rest.post(
  //   `${portalApiServerTest}${ENDPOINTS.requestOTP}`,
  //   login.requestOTPInvalidSignature
  // ),
  // rest.post(
  //   `${portalApiServerTest}${ENDPOINTS.requestOTP}`,
  //   login.requestOTPExceededFreq
  // ),
  // rest.post(
  //   `${portalApiServerTest}${ENDPOINTS.requestOTP}`,
  //   login.requestOTPRestricted
  // ),
  // rest.post(
  //   `${portalApiServerTest}${ENDPOINTS.requestOTP}`,
  //   login.requestOTPAccountDeletionUnavailable
  // ),
  // rest.post(
  //   `${portalApiServerTest}${ENDPOINTS.requestOTP}`,
  //   login.serverErrorResponse
  // ),
  // rest.post(
  //   `${portalApiServerTest}${ENDPOINTS.requestOTP}`,
  //   login.requestOTPIsGmail
  // ),
  // ====================================================
  /**
   * Verify OTP cases:
   */
  rest.post(
    `${portalApiServerTest}${ENDPOINTS.verifyOTP}`,
    login.verifyOTPSuccessWithoutCaptcha
  ),
  // rest.post(
  //   `${portalApiServerTest}${ENDPOINTS.verifyOTP}`,
  //   login.verifyOTPInvalidParam
  // ),
  // rest.post(
  //   `${portalApiServerTest}${ENDPOINTS.verifyOTP}`,
  //   login.verifyOTPExceededFreq
  // ),
  // rest.post(
  //   `${portalApiServerTest}${ENDPOINTS.verifyOTP}`,
  //   login.verifyOTPInvalid
  // ),
  // rest.post(
  //   `${portalApiServerTest}${ENDPOINTS.verifyOTP}`,
  //   login.verifyOTPExpired
  // ),
  // rest.post(
  //   `${portalApiServerTest}${ENDPOINTS.verifyOTP}`,
  //   login.verifyOTPAccNotExist
  // ),
  // rest.post(
  //   `${portalApiServerTest}${ENDPOINTS.verifyOTP}`,
  //   login.verifyOTPAccDeactivated
  // ),
  // rest.post(
  //   `${portalApiServerTest}${ENDPOINTS.verifyOTP}`,
  //   login.serverErrorResponse
  // ),
  // ====================================================
  /**
   * Verify OAuth cases:
   */
  rest.post(
    `${portalApiServerTest}${ENDPOINTS.verifyOAuth}`,
    login.verifyOTPSuccessWithoutCaptcha
  ),
  // rest.post(
  //   `${portalApiServerTest}${ENDPOINTS.verifyOAuth}`,
  //   login.verifyOTPAccNotExist
  // ),
  // rest.post(
  //   `${portalApiServerTest}${ENDPOINTS.verifyOAuth}`,
  //   login.verifyOTPAccDeactivated
  // ),
  // rest.post(
  //   `${portalApiServerTest}${ENDPOINTS.verifyOAuth}`,
  //   login.thirdPartyTokenInvalid
  // ),
  // rest.post(
  //   `${portalApiServerTest}${ENDPOINTS.verifyOAuth}`,
  //   login.serverErrorResponse
  // ),
  // ====================================================
  /**
   * Prepare delete cases:
   */
  rest.post(
    `${portalApiServerTest}${ENDPOINTS.prepareDelete}`,
    deleteAcc.prepareDeleteSuccess
  ),
  // rest.post(
  //   `${portalApiServerTest}${ENDPOINTS.prepareDelete}`,
  //   deleteAcc.prepareDeleteAccountNotExists
  // ),
  // rest.post(
  //   `${portalApiServerTest}${ENDPOINTS.prepareDelete}`,
  //   deleteAcc.prepareDeleteAccountUnauthorized
  // ),
  // rest.post(
  //   `${portalApiServerTest}${ENDPOINTS.prepareDelete}`,
  //   deleteAcc.deleteAccountDeactivated
  // ),
  // rest.post(
  //   `${portalApiServerTest}${ENDPOINTS.prepareDelete}`,
  //   login.serverErrorResponse
  // ),
  // ====================================================
  /**
   * Delete account cases:
   */
  rest.post(
    `${portalApiServerTest}${ENDPOINTS.deleteAccount}`,
    deleteAcc.deleteAccountSuccess
  ),
  // rest.post(
  //   `${portalApiServerTest}${ENDPOINTS.deleteAccount}`,
  //   deleteAcc.deleteAccountAccountNotExists
  // ),
  // rest.post(
  //   `${portalApiServerTest}${ENDPOINTS.deleteAccount}`,
  //   deleteAcc.deleteAccountUnauthorized
  // ),
  // rest.post(
  //   `${portalApiServerTest}${ENDPOINTS.deleteAccount}`,
  //   deleteAcc.deleteAccountDeactivated
  // ),
  // rest.post(
  //   `${portalApiServerTest}${ENDPOINTS.deleteAccount}`,
  //   login.serverErrorResponse
  // ),
  // ====================================================
];
