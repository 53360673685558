import { useDeviceInfoContext } from 'components/shared/DeviceInfo';
import { useLocaleContext } from 'components/shared/Locale';
import PropTypes from 'prop-types';
import React from 'react';
import styles from 'components/features/Launch.module.scss';
import AnimationScrollSpy from 'components/shared/AnimationScrollSpy';
import { ROUTE_FULL_PATH } from 'configs';
import Link from 'next/link';
import { FBPixelContactSalesTracking } from 'utils/fbq';
import LocaleImage from 'components/shared/LocaleImage';
import { GTagContactSalesTracking } from 'utils/ga';
import ExternalLink from '../shared/ExternalLink';

const Launch = ({ setRef, isEnter, top }) => {
  const { locale } = useLocaleContext();
  const { isMobile } = useDeviceInfoContext();

  const innerCx = [styles.inner];
  if (isEnter) {
    innerCx.push(styles.innerEnter);
  }

  return (
    <div className={styles.wrap}>
      {top}
      <div className={innerCx.join(' ')} ref={setRef}>
        <img src="/img/st-home-ic-map.png" className={styles.mapBg} alt="" />

        <div className={styles.launchRight}>
          <div className={styles.content}>
            <h2 className={styles.title}>
              {isMobile
                ? locale('website_homepage_enjoy_seatalk')
                : locale('website_footer_download_lauch_company')}
            </h2>
          </div>
          <div className={styles.startWrap}>
            {isMobile ? (
              <ExternalLink
                className={styles.start}
                href={ROUTE_FULL_PATH.DOWNLOAD}
              >
                {locale('website_download_download')}
              </ExternalLink>
            ) : (
              <Link href={ROUTE_FULL_PATH.CONTACT_SALES}>
                <a
                  className={styles.start}
                  onClick={() => {
                    FBPixelContactSalesTracking.enter();
                    GTagContactSalesTracking.enter();
                  }}
                >
                  {locale('website_contact_sales')}
                </a>
              </Link>
            )}
          </div>
        </div>

        <div className={styles.launchIcon}>
          <LocaleImage
            className={styles.launchIcon}
            src="login_img_illustration.png"
            alt={locale('website_launch_alt')}
          />
        </div>
      </div>
    </div>
  );
};

Launch.propTypes = {
  setRef: PropTypes.func.isRequired,
  isEnter: PropTypes.bool,
  top: PropTypes.node,
};

export default AnimationScrollSpy(Launch);
