import React from 'react';
import PropTypes from 'prop-types';
import styles from 'components/shared/Header/mobile/MobileNavTrigger.module.scss';
import cx from 'classnames';
import { useNavBarContext } from 'components/shared/MobileNav';

const MobileNavbarTrigger = ({ shouldHeaderTransparent }) => {
  const { openNavbar } = useNavBarContext();

  return (
    <div className={styles.mobileNavbar}>
      <div
        className={cx(
          styles.mobileNavbarTriggerIcon,
          shouldHeaderTransparent ? styles.coloredBg : ''
        )}
        onClick={openNavbar}
      >
        {Array(3)
          .fill(1)
          .map((i, index) => (
            <div key={index} className={styles.mobileNavbarTriggerIconLine} />
          ))}
      </div>
    </div>
  );
};

MobileNavbarTrigger.propTypes = {
  shouldHeaderTransparent: PropTypes.bool,
};

export default MobileNavbarTrigger;
