const ROOT = '/';
const ROOT_CN = '/cn/';
const FEATURES = 'features';
const DOWNLOAD = 'download';
const START = 'start';
const TERMS = 'terms';
const ONBOARD = 'onboard';
const CONTACT_SALES = 'contact-sales';
const LAUNCH = 'launch';
const UNSUBSCRIBE = 'unsubscribe';

export const ROOT_PATH =
  typeof window !== 'undefined' &&
  window.location.pathname.toLowerCase().indexOf('/cn') === 0
    ? ROOT_CN
    : ROOT;

export const ROUTE_FULL_PATH = {
  FEATURES: `${ROOT_PATH}${FEATURES}`,
  DOWNLOAD: `${ROOT_PATH}${DOWNLOAD}`,
  START: `${ROOT_PATH}${START}`,
  START_FAILED_API: `${ROOT_PATH}${START}/registration-received`,
  START_LOADING: `${ROOT_PATH}${START}/preparing`,
  START_ACCOUNT_SEA_GROUP_NOT_SUPPORTED: `${ROOT_PATH}${START}/email-not-supported`,
  START_ACCOUNT_BIND_TO_FB_AND_APPLE_NOT_SUPPORTED: `${ROOT_PATH}${START}/third-party-account-not-supported`,
  TERMS: `${ROOT_PATH}${TERMS}`,
  ONBOARD: `${ROOT_PATH}${ONBOARD}`,
  CONTACT_SALES: `${ROOT_PATH}${CONTACT_SALES}`,
  LAUNCH: `${ROOT_PATH}${LAUNCH}`,
  UNSUBSCRIBE: `${ROOT_PATH}${UNSUBSCRIBE}`,
};
