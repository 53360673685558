import { PortalResponseCodes } from 'consts';
import { getResponseEmptyData, getResponseWithoutData } from 'mocks/utils';

const prepareDeletionSuccessResponse = {
  code: PortalResponseCodes.Success,
  data: {
    name: 'OAuth username',
  },
};

export const prepareDeleteSuccess = (_, res, ctx) =>
  res(ctx.status(200), ctx.json(prepareDeletionSuccessResponse));

export const prepareDeleteAccountNotExists = (_, res, ctx) =>
  res(
    ctx.status(200),
    ctx.json(getResponseEmptyData(PortalResponseCodes.AccountNotExists))
  );

export const prepareDeleteAccountUnauthorized = (_, res, ctx) =>
  res(
    ctx.status(401),
    ctx.json(getResponseEmptyData(PortalResponseCodes.Unauthorized))
  );

export const deleteAccountSuccess = (_, res, ctx) =>
  res(
    ctx.status(200),
    ctx.json(getResponseWithoutData(PortalResponseCodes.Success))
  );

export const deleteAccountAccountNotExists = (_, res, ctx) =>
  res(
    ctx.status(200),
    ctx.json(getResponseWithoutData(PortalResponseCodes.AccountNotExists))
  );

export const deleteAccountUnauthorized = (_, res, ctx) =>
  res(
    ctx.status(401),
    ctx.json(getResponseWithoutData(PortalResponseCodes.Unauthorized))
  );

export const deleteAccountDeactivated = (_, res, ctx) =>
  res(
    ctx.status(200),
    ctx.delay(1000),
    ctx.json(getResponseEmptyData(PortalResponseCodes.AccountDeactivated))
  );
