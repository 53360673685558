import { useLocaleContext } from 'components/shared/Locale';
import React, { useMemo } from 'react';
import styles from 'components/shared/FooterSmall/index.module.scss';
import { ROUTE_FULL_PATH } from 'configs';
import Link from 'next/link';

const currentYear = new Date().getFullYear();

const FooterSmall = () => {
  const { locale } = useLocaleContext();

  const footerLinks = useMemo(
    () => [
      {
        name: locale('website_footer_corporate_tos'),
        to: `${ROUTE_FULL_PATH.TERMS}/corporate`,
      },
      {
        name: locale('website_footer_individual_tos'),
        to: `${ROUTE_FULL_PATH.TERMS}/individual`,
      },
      {
        name: locale('website_footer_privacy_policy'),
        to: `${ROUTE_FULL_PATH.TERMS}/privacy`,
      },
    ],
    []
  );

  return (
    <div className={styles.smallFooterWrapper}>
      <div className={styles.smallFooterLinks}>
        {footerLinks.map((footerLink, index) => (
          <Link key={index} href={footerLink.to}>
            <a className={styles.smallFooterLink}>{footerLink.name}</a>
          </Link>
        ))}
      </div>

      <div className={styles.smallFooterCopyright}>
        {locale('website_footer_copyright', {
          startYear: 2017,
          endYear: currentYear,
        })}
      </div>
    </div>
  );
};

export default FooterSmall;
