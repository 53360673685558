export const getCanonicalAttr = (language, path) => {
  // Record<language, domain>
  // refer to `getLocaleByTld` function in `src/locale/index.tsx`
  // for lang list by tld
  const langDomainMap = {
    en: 'https://seatalk.io',
    th: 'https://seatalk.in.th',
    id: 'https://seatalk.co.id',
    vi: 'https://seatalk.vn',
    'zh-tw': 'https://seatalk.tw',
  };

  const domain = langDomainMap[language] || langDomainMap.en;

  // can't use URL API since this function also run in server.
  // URL API is available only in browser
  // return `${domain}${path}`;
  const href = `${domain}${path}`;

  return { href, rel: 'canonical' };
};
