import { useDeviceInfoContext } from 'components/shared/DeviceInfo';
import { useLocaleContext } from 'components/shared/Locale';
import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import styles from 'components/shared/MobileNav/index.module.scss';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { ROUTE_FULL_PATH, ROOT_PATH } from 'configs';
import { getHelpCenterLink } from 'configs/env';
import { useRouter } from 'next/router';
import { openNewTab } from 'utils';

// using Context in case any other component want to know about the state of Navbar
const MobileNavbarContext = createContext(null);
export const useNavBarContext = () => useContext(MobileNavbarContext);

export function MobileNavbarProvider({ children }) {
  const [isNavbarOpen, setIsNavbarOpen] = useState(false);
  const openNavbar = useCallback(() => setIsNavbarOpen(true), []);
  const closeNavbar = useCallback(() => setIsNavbarOpen(false), []);

  return (
    <MobileNavbarContext.Provider
      value={{ isNavbarOpen, openNavbar, closeNavbar }}
    >
      {children}
    </MobileNavbarContext.Provider>
  );
}

MobileNavbarProvider.propTypes = {
  children: PropTypes.node,
};

const MobileNav = () => {
  const { locale, language } = useLocaleContext();
  const { isMobile } = useDeviceInfoContext();
  const { push } = useRouter();
  const [openNavItemSet, setOpenNavItemSet] = useState(new Set([1]));
  const { isNavbarOpen, closeNavbar } = useNavBarContext();

  const navItems = useMemo(
    () => [
      { name: locale('website_header_home'), to: ROOT_PATH },
      {
        name: locale('website_header_features'),
        to: ROUTE_FULL_PATH.FEATURES,
        children: [
          {
            name: locale('website_feature_a'),
            hash: 'communication',
          },
          { name: locale('website_feature_b'), hash: 'team' },
          {
            name: locale('website_feature_c'),
            hash: 'workspace',
          },
          {
            name: locale('website_feature_d'),
            hash: 'open',
          },
        ],
      },
      {
        name: locale('website_header_help_center'),
        href: getHelpCenterLink(language),
      },
      {
        name: locale('website_header_download'),
        to: ROUTE_FULL_PATH.DOWNLOAD,
      },
    ],
    []
  );

  return isMobile ? (
    <div
      className={cx(
        styles.mobileNavigationWrapper,
        isNavbarOpen ? styles.openMobileNavigationWrapper : ''
      )}
    >
      <div className={styles.mobileNavigationHeader}>
        <div className={styles.mobileNavigationLogo} />
        <div className={styles.mobileNavigationClose} onClick={closeNavbar}>
          {Array(2)
            .fill(1)
            .map((i, index) => (
              <div key={index} className={styles.mobileNavigationCloseLine} />
            ))}
        </div>
      </div>

      <div className={styles.mobileNavigationContent}>
        {navItems.map((navItem, navItemKey) => {
          const { name, to, href, children } = navItem;
          const isChildrenOpen = openNavItemSet.has(navItemKey);

          return (
            <div
              key={navItemKey}
              className={cx(
                styles.mobileNavigationItem,
                isChildrenOpen ? styles.openMobileNavigationItem : ''
              )}
            >
              <div
                className={styles.mobileNavigationItemName}
                onClick={() => {
                  if (to && !children) {
                    closeNavbar();

                    // Set timeout for better animation
                    setTimeout(() => {
                      push(to);
                    }, 300);
                  } else if (href) {
                    openNewTab(href);
                  } else {
                    if (openNavItemSet.has(navItemKey)) {
                      openNavItemSet.delete(navItemKey);
                    } else {
                      openNavItemSet.add(navItemKey);
                    }
                    setOpenNavItemSet(new Set(openNavItemSet));
                  }
                }}
              >
                {name}

                {children && (
                  <div className={styles.mobileNavigationItemOpenChildren} />
                )}
              </div>

              {children && (
                <div className={cx(styles.mobileNavigationItemChildren)}>
                  {children.map((child, navItemChildKey) => (
                    <div
                      key={navItemChildKey}
                      className={styles.mobileNavigationItemChild}
                      onClick={() => {
                        closeNavbar();

                        // Set timeout for better animation
                        setTimeout(() => {
                          push(`${ROUTE_FULL_PATH.FEATURES}/${child.hash}`);
                        }, 300);
                      }}
                    >
                      {child.name}
                    </div>
                  ))}
                </div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  ) : null;
};

export default MobileNav;
