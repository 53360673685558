import DeviceInfoProvider from 'components/shared/DeviceInfo';
import LocationInfoProvider from 'components/shared/LocationInfo';
import DownloadSeatalkProvider from 'components/shared/DownloadSeatalk';
import Footer from 'components/shared/Footer';
import FooterSmall from 'components/shared/FooterSmall';
import LocaleProvider from 'components/shared/Locale';
import PageViewTracking from 'components/shared/PageViewTracking';
import Header from 'components/shared/Header';
import MobileNav, { MobileNavbarProvider } from 'components/shared/MobileNav';
import { ROOT_PATH, ROUTE_FULL_PATH } from 'configs';
import 'styles/globals.scss';
import Head from 'next/head';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useRouter } from 'next/router';
import { isLocal } from 'configs/env';
import { worker } from 'mocks/browser';
import ToastProvider from 'components/shared/Toast/ToastProvider';
import Toast from 'components/shared/Toast';
import MaintenanceBanner from 'components/shared/MaintenanceBanner';
import styles from './_app.module.scss';
import locale, { getClientLanguage } from '../locale';

// https://nextjs.org/docs/advanced-features/measuring-performance
export function reportWebVitals(metric) {
  if (['local', 'test'].includes(process.env.ENV)) {
    console.info(
      `[${metric.label.toUpperCase()}] ${metric.name}: ${Number.parseFloat(
        metric.value / 1000
      ).toFixed(2)} seconds`
    );
  }
}

(async () => {
  if (isLocal && typeof window !== 'undefined') {
    const startWorker = async function startWorkerFunc() {
      await worker.start({
        serviceWorker: {
          url: '/mockServiceWorker.js',
        },
        onUnhandledRequest: ({ method, url: { href } }) => {
          // eslint-disable-next-line no-console
          console.warn('[MSW]Unhandled request.', { method, href });
        },
        waitUntilReady: true,
      });
    };

    startWorker();
  }
})();

function App({
  Component,
  isHeadersAcceptWebp,
  pageProps,
  serverLanguage,
  serverUserAgent,
  err,
}) {
  const router = useRouter();
  const { pathname } = router;
  const language =
    typeof window !== 'undefined' ? getClientLanguage() : serverLanguage;
  const footer = (() => {
    if (
      [
        ROUTE_FULL_PATH.START,
        ROUTE_FULL_PATH.START_LOADING,
        ROUTE_FULL_PATH.START_FAILED_API,
        ROUTE_FULL_PATH.START_ACCOUNT_SEA_GROUP_NOT_SUPPORTED,
        ROUTE_FULL_PATH.START_ACCOUNT_BIND_TO_FB_AND_APPLE_NOT_SUPPORTED,
        ROUTE_FULL_PATH.LAUNCH,
        ROUTE_FULL_PATH.UNSUBSCRIBE,
      ].includes(pathname)
    ) {
      return <FooterSmall />;
    }
    return ROUTE_FULL_PATH.ONBOARD === pathname ? null : <Footer />;
  })();

  const [toastMessage, setToastMessage] = useState('');

  return (
    <DeviceInfoProvider
      serverUserAgent={serverUserAgent}
      isHeadersAcceptWebp={isHeadersAcceptWebp}
    >
      <LocationInfoProvider>
        <LocaleProvider language={language}>
          <DownloadSeatalkProvider>
            <PageViewTracking>
              <MobileNavbarProvider>
                <div className="wrapper">
                  <MaintenanceBanner />
                  <Head>
                    <title>{locale('website_page_title')}</title>
                    <meta charSet="UTF-8" />
                    <meta
                      name="viewport"
                      content="width=device-width, initial-scale=1, maximum-scale=5, minimum-scale=1"
                    />
                    <link
                      href="https://fonts.googleapis.com/css?family=Open+Sans:400,600,700&display=swap"
                      rel="stylesheet"
                    />
                  </Head>

                  {![ROOT_PATH, ROUTE_FULL_PATH.ONBOARD].includes(pathname) && (
                    <Header />
                  )}
                  <Toast
                    isShow={!!toastMessage}
                    message={toastMessage}
                    callbackOnToastHidden={() => setToastMessage('')}
                  />
                  <div className={styles.appWrapper} lang={language}>
                    <div className={styles.main}>
                      <ToastProvider setToastMessage={setToastMessage}>
                        <Component {...pageProps} err={err} />
                      </ToastProvider>
                    </div>
                    {footer}
                    <div className="sz-deploy" />
                    <MobileNav />
                  </div>
                </div>
              </MobileNavbarProvider>
            </PageViewTracking>
          </DownloadSeatalkProvider>
        </LocaleProvider>
      </LocationInfoProvider>
    </DeviceInfoProvider>
  );
}

App.propTypes = {
  Component: PropTypes.any,
  isHeadersAcceptWebp: PropTypes.bool,
  pageProps: PropTypes.any,
  serverLanguage: PropTypes.string,
  serverUserAgent: PropTypes.string,
  err: PropTypes.object,
};

App.getInitialProps = () => ({});

export default App;
