import { useLocaleContext } from 'components/shared/Locale';
import React from 'react';
import cx from 'classnames';
import Options from 'components/shared/Options';
import headerSelectStyles from 'components/shared/Header/desktop/HeaderSelect.module.scss';
import { languageNames, updateLanguage } from 'locale';
import headerStyles from 'components/shared/Header/index.module.scss';
import styles from 'components/shared/Header/desktop/LanguageSelect.module.scss';
import HeaderSelect from 'components/shared/Header/desktop/HeaderSelect';

const languageOptions = Object.keys(languageNames).reduce(
  (acc, key) => [...acc, { option: languageNames[key], value: key }],
  []
);

const LanguageSelect = () => {
  const { language } = useLocaleContext();

  return (
    <HeaderSelect
      id="language-select"
      className={cx(headerStyles.navBarItem, styles.languageBtn)}
    >
      <div className={headerStyles.navBarItemGlobeIcon} />
      <div>{languageNames[language]}</div>
      <div className={headerStyles.navBarItemArrow} />
      <div
        className={cx(
          headerSelectStyles.optionWrap,
          styles.languageSelectOptionWrapper
        )}
      >
        <div className={styles.languageSelectOptionWrapperInner}>
          <Options
            options={languageOptions}
            handleChange={(value) => updateLanguage(value)}
            wrapperStyles={{ maxHeight: 'none', border: 'none' }}
          />
        </div>
      </div>
    </HeaderSelect>
  );
};

export default LanguageSelect;
