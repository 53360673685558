import React, { createContext, useContext } from 'react';
import { func, node } from 'prop-types';

export const ToastContext = createContext(null);
export const useToastContext = () => useContext(ToastContext);

function ToastProvider({ children, setToastMessage }) {
  return (
    <ToastContext.Provider value={{ setToastMessage }}>
      {children}
    </ToastContext.Provider>
  );
}

ToastProvider.propTypes = {
  setToastMessage: func,
  children: node,
};

export default ToastProvider;
