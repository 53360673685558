// MMS parameter documentation: https://confluence.shopee.io/pages/viewpage.action?pageId=1072573757
import { getMMSLink } from 'configs/env';
import { LanguageEnum } from 'locale';
import ImageToMmsMap from 'locale/mms.json';

const mmsUrl = getMMSLink();

export default function mmsLoader({ isAcceptWebp, src, width, quality }) {
  const params = [`resize_w${width}`, `quality_q${quality}`];
  const mmsHash = getMmsHash(src);
  const imageFormat = isAcceptWebp ? 'webp' : 'png';

  // To make PNG image lossy so could be compressed
  if (!isAcceptWebp) {
    params.push('format_pT');
  }

  if (mmsHash) {
    return `${mmsUrl}/${mmsHash}@${params.join('@')}.${imageFormat}`;
  }
  return src;
}

/**
 * A function to determine which MMS Hash to get from the given src string
 * image src format in this service is:
 * - default locale: '/img/${filename}'
 * - specific locale: '/img/${locale}/${filename}'
 * @param {string} src
 * @returns string
 */
function getMmsHash(src) {
  let locale = LanguageEnum.EN;
  let fileName;
  const srcPath = src.split('/');
  if (srcPath.length > 3) {
    // eslint-disable-next-line prefer-destructuring
    locale = srcPath[2];
    fileName = src.replace(`/img/${locale}/`, '');
  } else {
    fileName = src.replace('/img/', '');
  }
  return ImageToMmsMap[locale][fileName];
}
