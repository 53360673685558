import React, { createContext, useContext, useCallback, useState } from 'react';
import { node } from 'prop-types';
import { useDeviceInfoContext } from 'components/shared/DeviceInfo';
import { useLocationInfoContext } from 'components/shared/LocationInfo';
import {
  downloadDesktopApp,
  checkDownloadUrl,
  hackDetectM1Chip,
  openUrl,
} from 'utils';
import {
  IOS_DOWNLOAD_LINK,
  IOS_CN_DOWNLOAD_LINK,
  ANDROID_DOWNLOAD_LINK,
  ANDROID_CN_DOWNLOAD_LINK,
  HUAWEI_DOWNLOAD_LINK,
} from 'configs';
import { useLocaleContext } from 'components/shared/Locale';
import classnames from 'classnames';
import styles from './index.module.scss';

export const DownloadSeatalkContext = createContext(null);
export const useDownloadSeatalkContext = () =>
  useContext(DownloadSeatalkContext);

function DownloadSeatalkProvider({ children }) {
  const { locale } = useLocaleContext();
  const { isCN } = useLocationInfoContext();
  const { isMobile, isAndroid, isIOS, isHuaWei, isMacOS, isWindows } =
    useDeviceInfoContext();
  const [isShowErrorToast, setIsShowErrorToast] = useState(false);

  const displayErrorToast = useCallback(() => {
    setIsShowErrorToast(true);
    setTimeout(() => {
      setIsShowErrorToast(false);
    }, 1000);
  }, []);

  async function downloadSeatalk() {
    if (isMobile) {
      // 移动端
      let link;
      if (isHuaWei) {
        link = ANDROID_CN_DOWNLOAD_LINK;
      } else if (isAndroid) {
        link = isCN ? ANDROID_CN_DOWNLOAD_LINK : ANDROID_DOWNLOAD_LINK;
      } else if (isIOS) {
        link = isCN ? IOS_CN_DOWNLOAD_LINK : IOS_DOWNLOAD_LINK;
      }
      link && openUrl(link);
      const isAvailable = await checkDownloadUrl(link);
      !isAvailable && displayErrorToast();
    } else {
      // 桌面端
      if (isMacOS) {
        const isMacM1 = hackDetectM1Chip() || false;
        downloadDesktopApp(isMacOS, isMacM1, displayErrorToast);
      }
      if (isWindows) {
        const isMacM1 = false;
        downloadDesktopApp(isMacOS, isMacM1, displayErrorToast);
      }
    }
  }

  // 在Footer组件内的seatalk下载
  async function downloadMobileSeatalkInFooter(key) {
    key = key.toLowerCase();
    let link;
    if (key === 'appstore') {
      link = isCN ? IOS_CN_DOWNLOAD_LINK : IOS_DOWNLOAD_LINK;
    } else if (key === 'playstore') {
      link = isHuaWei ? HUAWEI_DOWNLOAD_LINK : ANDROID_DOWNLOAD_LINK;
    } else if (key === 'apk') {
      link = ANDROID_CN_DOWNLOAD_LINK;
    }
    link && openUrl(link);
    const isAvailable = await checkDownloadUrl(link);
    !isAvailable && displayErrorToast();
  }

  return (
    <DownloadSeatalkContext.Provider
      value={{
        downloadSeatalk,
        displayErrorToast,
        downloadMobileSeatalkInFooter,
      }}
    >
      <div className={styles.downloadContainer}>
        {children}
        <div
          className={classnames(
            styles.downloadErrorToast,
            isShowErrorToast && styles.downloadErrorToastEnter
          )}
        >
          {locale('website_download_failed_toast')}
        </div>
      </div>
    </DownloadSeatalkContext.Provider>
  );
}

DownloadSeatalkProvider.propTypes = {
  children: node,
};

export default DownloadSeatalkProvider;
