export const SENTRY_DSN =
  'https://f2b2eaac3b84ccf0380a281eb182b654@o506518.ingest.us.sentry.io/4508040047427584';

// If the log pollute the Sentry platform, it will be reduced
export const SENTRY_SAMPLE_RATE = 1.0;

export const SENTRY_ENV = (() => {
  if (process.env.ENV === 'local') {
    return 'dev';
  }

  return process.env.ENV || 'dev';
})();
