// Live: https://analytics.google.com/analytics/web/#/p304256088/reports/intelligenthome
// Test: https://analytics.google.com/analytics/web/#/p386771379/reports/intelligenthome
export const getGTagTrackingId = () =>
  process.env.ENV === 'live' ? 'G-LKEGBD0P0E' : 'G-V97KZ6D8VP';

export const GTagTrackPageView = () => {
  // Note: DO NOT remove query param in the URL
  // Which will accidentally block many tracking features of GA
  const pageViewObject = {
    page_title: window.location.pathname + window.location.hash,
    page_location: window.location.href,
    page_path: window.location.pathname + window.location.hash,
    send_to: getGTagTrackingId(),
  };

  window.gtag('event', 'page_view', pageViewObject);
};

export const GTagRegistrationTracking = {
  enter: () => {
    window.gtag('event', 'Button clicked', {
      event_category: 'Registration',
    });
  },
  submit: () => {
    window.gtag('event', 'Submit clicked', {
      event_category: 'Registration',
    });
  },
  complete: () => {
    window.gtag('event', 'Completed', {
      event_category: 'Registration',
    });
  },
};

export const GTagContactSalesTracking = {
  enter: () => {
    window.gtag('event', 'Button clicked', {
      event_category: 'Contact Sales',
    });
  },
  submit: () => {
    window.gtag('event', 'Submit clicked', {
      event_category: 'Contact Sales',
    });
  },
  complete: () => {
    window.gtag('event', 'Completed', {
      event_category: 'Contact Sales',
    });
  },
};

export const GTagHomeVideoTracking = {
  play: () => {
    window.gtag('event', 'Play', {
      event_category: 'Home Video',
    });
  },
  duration: (millisecondDuration) => {
    window.gtag('event', 'Watch duration', {
      event_category: 'Home Video',
      value: Math.round(millisecondDuration / 1000),
    });
  },
  finish: () => {
    window.gtag('event', 'Finish', {
      event_category: 'Home Video',
    });
  },
};

export const GTagScrollTracking = {
  scrollVertically: (scrollDepthThreshold) => {
    window.gtag('event', `${scrollDepthThreshold}%`, {
      event_category: 'Scroll',
      event_vertical: 'Vertical',
    });
  },
};
