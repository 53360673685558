import { useLocaleContext } from 'components/shared/Locale';
import React, { useMemo } from 'react';
import cx from 'classnames';
import Options from 'components/shared/Options';
import headerSelectStyles from 'components/shared/Header/desktop/HeaderSelect.module.scss';
import PropTypes from 'prop-types';
import { getOaLink, getSeatalkOpenLink } from 'configs/env';
import styles from 'components/shared/Header/desktop/ExternalAppSelect.module.scss';
import headerStyles from 'components/shared/Header/index.module.scss';
import { useRouter } from 'next/router';
import { ROUTE_FULL_PATH } from 'configs';
import { openNewTab } from 'utils';
import HeaderSelect from 'components/shared/Header/desktop/HeaderSelect';

const AppItem = ({ title, subtitle, imageClassName }) => (
  <div className={styles.appItem}>
    <div className={cx(styles.appItemImage, imageClassName)} />

    <div className={styles.appItemContent}>
      <div className={styles.appItemTitle}>{title}</div>
      <div className={styles.appItemSubTitle}>{subtitle}</div>
    </div>

    <div className={styles.appItemArrow} />
  </div>
);

AppItem.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  imageClassName: PropTypes.string.isRequired,
};

const ExternalAppSelect = () => {
  const { push } = useRouter();
  const { locale } = useLocaleContext();

  const appOptions = useMemo(
    () => [
      {
        value: 'launch',
        option: (
          <AppItem
            title={locale('website_header_web_login')}
            subtitle={locale('website_header_web_login_description')}
            imageClassName={styles.seatalkWebImage}
          />
        ),
      },
      {
        value: getOaLink(),
        option: (
          <AppItem
            title={locale('website_header_admin')}
            subtitle={locale('website_header_admin_description')}
            imageClassName={styles.oaImage}
          />
        ),
      },
      {
        value: getSeatalkOpenLink(),
        option: (
          <AppItem
            title={locale('website_header_sop')}
            subtitle={locale('website_header_sop_description')}
            imageClassName={styles.sopImage}
          />
        ),
      },
    ],
    []
  );

  return (
    <HeaderSelect
      className={cx(headerStyles.externalAppBtn, styles.externalAppBtn)}
    >
      <div>{locale('website_launch')}</div>
      <div className={headerStyles.navBarItemArrowBlue} />
      <div
        className={cx(
          headerSelectStyles.optionWrap,
          styles.externalAppSelectOptionWrapper
        )}
      >
        <div className={styles.externalAppSelectOptionWrapperInner}>
          <Options
            options={appOptions}
            handleChange={(value) => {
              if (value === 'launch') {
                push(ROUTE_FULL_PATH.LAUNCH);
              } else {
                openNewTab(value);
              }
            }}
            wrapperStyles={{ maxHeight: 'none', border: 'none' }}
          />
        </div>
      </div>
    </HeaderSelect>
  );
};

export default ExternalAppSelect;
