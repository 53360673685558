import React from 'react';
import { DeviceInfoContext } from 'components/shared/DeviceInfo';
import { LocaleContext } from 'components/shared/Locale';
import cx from 'classnames';
import styles from 'components/shared/Footer/index.module.scss';
import {
  ROUTE_FULL_PATH,
  getFooterMobileDownloadLinks,
  getFooterDesktopDownloadLinks,
} from 'configs';
import { languageNames, updateLanguage } from 'locale';
import { getHelpCenterLink } from 'configs/env';
import Link from 'next/link';
import { useDownloadSeatalkContext } from 'components/shared/DownloadSeatalk';
import ExternalLink from '../ExternalLink';

const Footer = React.memo(() => {
  const { downloadMobileSeatalkInFooter, displayErrorToast } =
    useDownloadSeatalkContext();

  const renderInternalUrlLink = ({ text, link, className = '' }) => {
    return (
      <li className={cx(styles.dlwrapper, className)} key={text}>
        <Link href={link}>
          <a className={styles.dllink}>{text}</a>
        </Link>
      </li>
    );
  };

  const renderUrlLink = ({ text, link, className = '' }) => {
    return (
      <li className={cx(styles.dlwrapper, className)} key={text}>
        <ExternalLink href={link} className={styles.dllink}>
          {text}
        </ExternalLink>
      </li>
    );
  };

  const renderMobileDownloadLink = ({ platform, key }) => {
    return (
      <li className={cx(styles.dlwrapper)} key={key}>
        <a
          className={styles.dllink}
          onClick={() => {
            downloadMobileSeatalkInFooter(key);
          }}
        >
          {platform}
        </a>
      </li>
    );
  };

  const renderDesktopDownloadLink = ({ platform, key, onClick }) => {
    return (
      <li className={styles.dlwrapper} key={key}>
        <a className={styles.dllink} onClick={onClick}>
          {platform}
        </a>
      </li>
    );
  };

  const renderLanguageLink = (lang) => {
    return (
      <li className={styles.dlwrapper} key={lang}>
        <span className={styles.dllink} onClick={() => updateLanguage(lang)}>
          {languageNames[lang]}
        </span>
      </li>
    );
  };

  const renderTop = () => {
    return (
      <DeviceInfoContext.Consumer>
        {({ isMobile }) => (
          <LocaleContext.Consumer>
            {({ language, locale }) => (
              <div className={styles.top}>
                <div className={styles.dlWrap}>
                  {!isMobile && (
                    <div className={styles.dl}>
                      <div className={styles.dltitle}>
                        {locale('website_download_download')}
                      </div>

                      <ul>
                        {getFooterMobileDownloadLinks(locale).map((link) =>
                          renderMobileDownloadLink(link)
                        )}
                        {getFooterDesktopDownloadLinks(
                          locale,
                          displayErrorToast
                        ).map((link) => renderDesktopDownloadLink(link))}
                      </ul>
                    </div>
                  )}

                  <div className={styles.dl}>
                    <div className={styles.dltitle}>
                      {locale('website_footer_product')}
                    </div>

                    <ul>
                      {renderInternalUrlLink({
                        text: locale('website_header_features'),
                        link: `${ROUTE_FULL_PATH.FEATURES}/communication`,
                      })}
                      {renderUrlLink({
                        text: locale('website_header_help_center'),
                        link: getHelpCenterLink(language),
                      })}
                      {renderUrlLink({
                        text: locale('website_header_web_login'),
                        link: 'https://seatalkweb.com/',
                      })}
                    </ul>
                  </div>

                  <div className={styles.dl}>
                    <div className={styles.dltitle}>
                      {locale('website_footer_language')}
                    </div>

                    <ul>
                      {Object.keys(languageNames).map((lang) =>
                        renderLanguageLink(lang)
                      )}
                    </ul>
                  </div>

                  <div className={styles.dl}>
                    <div className={styles.dltitle}>
                      {locale('website_footer_contact_us')}
                    </div>

                    <ul>
                      {renderUrlLink({
                        text: locale('website_footer_contact_us_form'),
                        link: `${getHelpCenterLink(language)}/contact`,
                      })}
                    </ul>

                    <div
                      className={styles.dltitle}
                      style={{ marginTop: '27px' }}
                    >
                      {locale('website_footer_legal')}
                    </div>

                    <ul>
                      {renderInternalUrlLink({
                        text: locale('website_footer_corporate_tos'),
                        link: `${ROUTE_FULL_PATH.TERMS}/corporate`,
                        className: styles.longName,
                      })}
                      {renderInternalUrlLink({
                        text: locale('website_footer_individual_tos'),
                        link: `${ROUTE_FULL_PATH.TERMS}/individual`,
                        className: styles.longName,
                      })}
                      {renderInternalUrlLink({
                        text: locale('website_footer_privacy_policy'),
                        link: `${ROUTE_FULL_PATH.TERMS}/privacy`,
                      })}
                    </ul>
                  </div>
                </div>

                <div className={styles.logo} />
              </div>
            )}
          </LocaleContext.Consumer>
        )}
      </DeviceInfoContext.Consumer>
    );
  };

  const year = new Date().getFullYear();

  return (
    <LocaleContext.Consumer>
      {({ locale }) => (
        <div className={styles.wrap}>
          <div className={styles.inner}>
            {renderTop()}

            <div className={styles.btm}>
              <div className={styles.copyright}>
                {locale('website_footer_copyright', {
                  startYear: 2017,
                  endYear: year,
                })}
              </div>
            </div>
          </div>
        </div>
      )}
    </LocaleContext.Consumer>
  );
});
export default Footer;
