import { isLive, isStaging } from 'configs/env';

// For TEST env, we only use 1 tracking ID for all domains
// For STAGING and LIVE env, we use 1 tracking ID for each domain
const testFacebookPixelTrackingId = '2088495777948196';
const facebookPixelTrackingIdByDomain = {
  io: '372236983988376',
  sg: '2791839124422649',
  id: '1017227038756129',
  vn: '295803791701842',
  th: '286496139463611',
  tw: '401203341317579',
  ph: '1021165138349768',
  my: '114198910419475',
};

export const initFacebookPixelPageViewTracking = () => {
  const domain = window.location.origin.split('.').pop();

  if (!isStaging) {
    fbq(
      'init',
      isLive
        ? facebookPixelTrackingIdByDomain[domain]
        : testFacebookPixelTrackingId
    );
    fbq('track', 'PageView');
  }
};

export const FBPixelRegistrationTracking = {
  enter: () => {
    fbq('trackCustom', 'ClickGetStartedButton');
  },
  complete: () => {
    fbq('trackCustom', 'CompleteRegistrationSuccessfully');
  },
};

export const FBPixelContactSalesTracking = {
  enter: () => {
    fbq('trackCustom', 'ClickContactSalesButton');
  },
  complete: () => {
    fbq('trackCustom', 'CompleteContactSalesSuccessfully');
  },
};
