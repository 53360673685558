import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { checkDownloadUrl } from 'utils';

function ExternalLink({ children, href, target, ...props }) {
  const checkUrl = useCallback(
    (e) => {
      const isCurrentTab = !target || target === '_self';
      // If target is current tab
      // Need to fetch before redirecting
      checkDownloadUrl(href);
      if (isCurrentTab) {
        e.preventDefault();
        window.location = href;
      }
    },
    [href, target]
  );

  return (
    <a href={href} target={target} onClick={checkUrl} {...props}>
      {children}
    </a>
  );
}

ExternalLink.propTypes = {
  children: PropTypes.node.isRequired,
  href: PropTypes.string.isRequired,
  target: PropTypes.string,
};

export default ExternalLink;
