import { hackDetectM1Chip, downloadDesktopApp } from 'utils';
import { isLive, isStaging } from './env';

export * from './router';

export const getIsMobileByUA = (userAgent) => {
  const isMobileButNotAndroidByUserAgent =
    /webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent);
  const isAndroidMobile = /Android/.test(userAgent) && /Mobile/.test(userAgent);
  const isMobile = isMobileButNotAndroidByUserAgent || isAndroidMobile;

  if (typeof window !== 'undefined') {
    return isMobile && window.innerWidth < 767;
  }

  return isMobile;
};

export const IOS_DOWNLOAD_LINK =
  'https://itunes.apple.com/us/app/seatalk/id1336516770?mt=8';
export const IOS_CN_DOWNLOAD_LINK =
  'https://apps.apple.com/cn/app/seatalk-cn/id1436472621';

export const ANDROID_DOWNLOAD_LINK =
  'https://play.google.com/store/apps/details?id=com.seagroup.seatalk';
// ANDROID_CN_DOWNLOAD_LINK
export const ANDROID_CN_DOWNLOAD_LINK = isLive
  ? 'https://portal.haiserve.com/package/beta'
  : isStaging
  ? 'https://portal.staging.haiserve.com/package/beta'
  : 'https://portal.test.haiserve.com/package/beta';

export const HUAWEI_DOWNLOAD_LINK =
  'https://appgallery.cloud.huawei.com/ag/n/app/C100845731?source=appshare&subsource=C100845731&shareTo=com.seagroup.seatalk&shareFrom=appmarket';

export const HUAWEI_CN_DOWNLOAD_LINK = ANDROID_CN_DOWNLOAD_LINK;

export const APP_LINK =
  isLive || isStaging
    ? 'https://link.seatalk.io/onboard'
    : 'https://link.test.seatalk.io/onboard';

export const getMobileDownloadLinks = (locale, isCN) => {
  return [
    {
      store: locale('website_download_app_store'),
      platform: locale('website_footer_ios'),
      link: isCN ? IOS_CN_DOWNLOAD_LINK : IOS_DOWNLOAD_LINK,
      key: 'appstore',
    },
    {
      store: locale('website_download_google_play'),
      platform: locale('website_footer_android'),
      link: ANDROID_DOWNLOAD_LINK,
      key: 'playstore',
    },
    {
      store: locale('website_update_huawei_store'),
      platform: locale('website_footer_huawei'),
      link: HUAWEI_DOWNLOAD_LINK,
      key: 'huaweistore',
    },
    {
      store: locale('website_download_android_bundle'),
      platform: locale('website_footer_android_cn'),
      link: ANDROID_CN_DOWNLOAD_LINK,
      key: 'androidbundle',
    },
  ];
};

export const getFooterMobileDownloadLinks = (locale) => {
  return [
    {
      store: locale('website_download_app_store'),
      platform: locale('website_footer_ios'),
      link: IOS_DOWNLOAD_LINK,
      key: 'appstore',
    },
    {
      store: locale('website_download_google_play'),
      platform: locale('website_footer_android'),
      link: ANDROID_DOWNLOAD_LINK,
      key: 'playstore',
    },

    {
      store: locale('website_download_download'),
      platform: locale('website_footer_android_cn'),
      link: ANDROID_CN_DOWNLOAD_LINK,
      key: 'apk',
    },
  ];
};

export const getDesktopDownloadLinks = (locale, callback = null) => [
  {
    platform: locale('website_footer_windows'),
    key: 'windows',
    onClick: () => downloadDesktopApp(false, false, callback),
  },
  {
    platform: locale('website_footer_mac_intel'),
    key: 'macintel',
    onClick: () => downloadDesktopApp(true, false, callback),
  },
  {
    platform: locale('website_footer_mac_m1'),
    key: 'macm1',
    onClick: () => downloadDesktopApp(true, true, callback),
  },
];

export const getFooterDesktopDownloadLinks = (locale, callback = null) => [
  {
    platform: locale('website_footer_windows'),
    key: 'windows',
    onClick: () => downloadDesktopApp(false, false, callback),
  },
  {
    platform: locale('website_footer_mac'),
    key: 'mac',
    onClick: () => {
      const isMac = true;
      const isMacM1 = hackDetectM1Chip() || false;
      downloadDesktopApp(isMac, isMacM1, callback);
    },
  },
];

export const CONTACT_EMAIL = 'support@seatalk.biz';
