import { PortalResponseCodes } from 'consts';
import { getResponseEmptyData, getResponseWithoutData } from 'mocks/utils';

const OTPSuccessVerifyResponse = {
  code: PortalResponseCodes.Success,
  data: {
    token: 'xxxx',
    /**
     * set to 10 minutes from now
     */
    expiration: Math.floor(Date.now() / 1000) + 10 * 60,
  },
};

export const requestOTPSuccess = (req, res, ctx) => {
  if (req?.body?.captcha_signature) {
    return res(
      ctx.status(200),
      ctx.delay(800),
      ctx.json(getResponseWithoutData(PortalResponseCodes.Success))
    );
  }
  return res(
    ctx.status(200),
    ctx.delay(800),
    ctx.json(getResponseWithoutData(PortalResponseCodes.NeedCaptcha))
  );
};

export const requestOTPInvalidParam = (_, res, ctx) =>
  res(
    ctx.status(200),
    ctx.json(getResponseWithoutData(PortalResponseCodes.ParamInvalid))
  );

export const requestOTPAccountDeletionUnavailable = (_, res, ctx) =>
  res(
    ctx.status(200),
    ctx.json(
      getResponseWithoutData(PortalResponseCodes.AccountDeletionUnavailable)
    )
  );

export const requestOTPInvalidSignature = (_, res, ctx) =>
  res(
    ctx.status(200),
    ctx.json(
      getResponseWithoutData(PortalResponseCodes.InvalidCaptchaSingature)
    )
  );

export const requestOTPRestricted = (_, res, ctx) =>
  res(
    ctx.status(200),
    ctx.json(getResponseWithoutData(PortalResponseCodes.OTPRestricted))
  );

export const requestOTPExceededFreq = (_, res, ctx) =>
  res(
    ctx.status(200),
    ctx.json(getResponseWithoutData(PortalResponseCodes.ResourceQuotaExceeded))
  );

export const requestOTPIsGmail = (_, res, ctx) =>
  res(
    ctx.delay(300),
    ctx.status(200),
    ctx.json(getResponseWithoutData(PortalResponseCodes.EmailIsGoogle))
  );

export const verifyOTPInvalid = (_, res, ctx) =>
  res(
    ctx.status(200),
    ctx.json(getResponseEmptyData(PortalResponseCodes.OTPInvalid))
  );

export const verifyOTPExpired = (_, res, ctx) =>
  res(
    ctx.status(200),
    ctx.json(getResponseEmptyData(PortalResponseCodes.OTPExpired))
  );

export const verifyOTPAccNotExist = (_, res, ctx) =>
  res(
    ctx.status(200),
    ctx.json(getResponseEmptyData(PortalResponseCodes.AccountNotExists))
  );

export const verifyOTPAccDeactivated = (_, res, ctx) =>
  res(
    ctx.delay(800),
    ctx.status(200),
    ctx.json(getResponseEmptyData(PortalResponseCodes.AccountDeactivated))
  );

export const verifyOTPSuccessWithoutCaptcha = (_, res, ctx) =>
  res(ctx.status(200), ctx.json(OTPSuccessVerifyResponse), ctx.delay(500));

export const verifyOTPInvalidParam = (_, res, ctx) =>
  res(
    ctx.status(200),
    ctx.json(getResponseEmptyData(PortalResponseCodes.ParamInvalid))
  );

export const verifyOTPExceededFreq = (_, res, ctx) =>
  res(
    ctx.status(200),
    ctx.json(getResponseEmptyData(PortalResponseCodes.ResourceQuotaExceeded))
  );

export const thirdPartyTokenInvalid = (_, res, ctx) =>
  res(
    ctx.status(200),
    ctx.json(getResponseEmptyData(PortalResponseCodes.ThirdPartyTokenInvalid))
  );

export const serverErrorResponse = (_, res, ctx) =>
  res(
    ctx.status(500),
    ctx.json(getResponseEmptyData(PortalResponseCodes.ServerError))
  );
