import * as Sentry from '@sentry/nextjs';

/**
 * Sends a log of an HTTP error response to Sentry.
 *
 * In BE standard, Be return 200 status code even though there's an error with the respective error code.
 * Therefore, this function sends an event to Sentry with details about an HTTP error response,
 * including the request path, method, and bodies of the request and response.
 *
 * @param {Object} options - The options for the HTTP error response log.
 * @param {string} [options.path=''] - The path of the HTTP request.
 * @param {string} [options.method='get'] - The HTTP method used for the request.
 * @param {Object} [options.requestBody={}] - The body of the HTTP request.
 * @param {Object} [options.responseBody={}] - The body of the HTTP response.
 */
export const sendHttpErrorResponseLog = ({
  path = '',
  method = 'get',
  requestBody = {},
  responseBody = {},
}) => {
  const serverUrl =
    typeof window !== 'undefined' ? `${window.location.origin}${path}` : path;

  Sentry.captureEvent({
    message: `HTTP Response Error: [${method}] ${serverUrl}`,
    level: 'info',
    timestamp: new Date().getTime() / 1000,
    extra: {
      targetUrl: serverUrl,
      requestMethod: method,
      requestBody,
      responseBody,
    },
  });
};
