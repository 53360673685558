import React from 'react';

function withIgnoreTest(WrappedComponent) {
  return (props) => {
    return process.env.NODE_ENV !== 'test' ? (
      <WrappedComponent {...props} />
    ) : null;
  };
}

export default withIgnoreTest;
