import React, { useState, useEffect } from 'react';
import axios from 'axios';
import * as DOMPurify from 'dompurify';
import { isLive } from 'configs/env';

const purifyCfg = {
  ALLOWED_TAGS: ['p', 'strong'],
  ALLOWED_ATTR: ['class'],
};

const configURL = {
  nonLive:
    'https://deo.shopeemobile.com/shopee/shopee-seatalkcdn-test-sg/seatalk-banner-config.json',
  live: 'https://deo.shopeemobile.com/shopee/shopee-seatalkcdn-live-sg/seatalk-banner-config.json',
};

function MaintenanceBanner() {
  const [maintenanceConfig, setMaintenanceConfig] = useState(null);

  useEffect(() => {
    const getMaintenanceConfig = async () => {
      try {
        const targetConfigUrl = isLive ? configURL.live : configURL.nonLive;
        const { data: resJson } = await axios.get(targetConfigUrl);

        setMaintenanceConfig({
          ...resJson,
          content: DOMPurify.sanitize(resJson.content, purifyCfg),
        });
      } catch {
        setMaintenanceConfig(null);
      }
    };
    getMaintenanceConfig();
  }, []);

  if (maintenanceConfig?.show?.official_website) {
    return (
      <div
        id="announcement-banner"
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: maintenanceConfig?.content,
        }}
      />
    );
  }

  return null;
}

export default MaintenanceBanner;
