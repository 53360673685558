import { useDeviceInfoContext } from 'components/shared/DeviceInfo';
import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Link from 'next/link';
import DesktopNav from 'components/shared/Header/desktop/DesktopNav';
import MobileNavTrigger from 'components/shared/Header/mobile/MobileNavTrigger';
import styles from 'components/shared/Header/index.module.scss';
import { ROOT_PATH, ROUTE_FULL_PATH } from 'configs';
import cx from 'classnames';
import { useRouter } from 'next/router';
import FeatureWrapper from 'components/features/FeatureWrapper';
import withIgnoreTest from '../IgnoreTest';
import useCustomScrollPosition from '../../../hooks/useCustomScrollPosition';

const disabledNavItemRoutes = [
  ROUTE_FULL_PATH.START,
  ROUTE_FULL_PATH.UNSUBSCRIBE,
];

const Header = ({ shouldMakeBgTransparentWhenScroll }) => {
  const router = useRouter();
  const { isMobile } = useDeviceInfoContext();

  const [isScrollPosAtTop, setIsScrollPosAtTop] = useState(true);
  const [headerLeftPosition, setHeaderLeftPosition] = useState(0);

  // using useCustomScrollPosition to debounce calling scroll vertically handler
  // in order to prevent scroll vertically handler from being called too much, which hurts the performance
  // the animation is still good
  useCustomScrollPosition(
    ({ currPos }) => {
      setIsScrollPosAtTop(currPos.y === 0);
    },
    [setIsScrollPosAtTop],
    false,
    false,
    100
  );

  // this handler is to calculate header position when user scrolls horizontally
  // (the calculation needs to be done because header's position is fixed)
  // if we use useCustomScrollPosition, the animation is not smooth
  // (can refer here for result https://www.loom.com/share/d3d587d9bdd145d793292f8868d39c3e)
  // therefore, this handler is used in window.addEventListener
  const handleWindowScrollX = useCallback(() => {
    setHeaderLeftPosition(-window.pageXOffset);
  }, []);

  useEffect(() => {
    setIsScrollPosAtTop(window.scrollY === 0);

    if (!isMobile) {
      window.addEventListener('scroll', handleWindowScrollX);
    }

    return function () {
      if (!isMobile) {
        window.addEventListener('scroll', handleWindowScrollX);
      }
    };
  }, []);

  const shouldHeaderTransparent =
    shouldMakeBgTransparentWhenScroll && isScrollPosAtTop;
  const isDisabledNavItem =
    disabledNavItemRoutes.filter(
      (route) => router.pathname.indexOf(route) === 0
    ).length > 0;

  return (
    <>
      <div
        className={cx(
          shouldHeaderTransparent ? styles.transparentHeader : styles.header,
          isDisabledNavItem ? styles.disabledNavItemHeader : ''
        )}
        style={{ left: `${headerLeftPosition}px` }}
      >
        <div className={styles.headerInner}>
          <Link href={ROOT_PATH}>
            <a className={styles.headerLogo} />
          </Link>

          {!isMobile && !isDisabledNavItem && <DesktopNav />}
        </div>

        {isMobile && !isDisabledNavItem && (
          <MobileNavTrigger shouldHeaderTransparent={shouldHeaderTransparent} />
        )}
      </div>
      <FeatureWrapper />
    </>
  );
};

Header.propTypes = {
  shouldMakeBgTransparentWhenScroll: PropTypes.bool,
};

export default withIgnoreTest(Header);
