import { getIsMobileByUA } from 'configs';
import React, { createContext, useContext, useMemo } from 'react';
import { bool, node, string } from 'prop-types';
import { isBrowserSupportWebp } from 'utils';

export const DeviceInfoContext = createContext(null);
export const useDeviceInfoContext = () => useContext(DeviceInfoContext);

function DeviceInfoProvider({
  isHeadersAcceptWebp,
  serverUserAgent,
  children,
}) {
  const isAcceptWebp = useMemo(
    () =>
      typeof document !== 'undefined'
        ? isBrowserSupportWebp()
        : isHeadersAcceptWebp,
    [isHeadersAcceptWebp]
  );

  const ua = useMemo(
    () =>
      typeof window !== 'undefined'
        ? navigator.userAgent
        : serverUserAgent || '',
    []
  );
  const isTouchDevice = useMemo(
    () => /Android|iPhone|iPad|iPod/i.test(ua),
    [ua]
  );
  const isMobile = useMemo(() => getIsMobileByUA(ua), [ua]);
  const isAndroid = useMemo(() => isMobile && /Android/i.test(ua), [ua]);
  const isIOS = useMemo(() => isMobile && /iPad|iPhone|iPod/i.test(ua), [ua]);
  const isMacOS = useMemo(() => ua.toUpperCase().indexOf('MAC') >= 0, [ua]);
  const isWindows = useMemo(() => ua.toUpperCase().indexOf('WIN') >= 0, [ua]);

  // Detect Huawei Device By ua
  // Reference Repo: https://github.com/faisalman/ua-parser-js
  // Reference Src: https://github.com/faisalman/ua-parser-js/blob/1bf1c732c72ff6d6102591fe4a93a2284c826a52/src/ua-parser.js#L388
  const isHuaWei = useMemo(() => {
    const RegHuaweiTablet =
      /\b((?:ag[rs][23]?|bah2?|sht?|btv)-a?[lw]\d{2})\b(?!.+d\/s)/i;
    const RegHuaweiPhone1 = /(?:huawei|honor)([-\w ]+)[;\)]/i;
    const RegHuaweiPhone2 =
      /\b(nexus 6p|\w{2,4}-[atu]?[ln][01259x][012359][an]?)\b(?!.+d\/s)/i;

    return (
      RegHuaweiTablet.test(ua) ||
      RegHuaweiPhone1.test(ua) ||
      RegHuaweiPhone2.test(ua)
    );
  }, [ua]);

  return (
    <DeviceInfoContext.Provider
      value={{
        isTouchDevice,
        isMobile,
        isAndroid,
        isIOS,
        isHuaWei,
        isMacOS,
        isWindows,
        isAcceptWebp,
      }}
    >
      {children}
    </DeviceInfoContext.Provider>
  );
}

DeviceInfoProvider.propTypes = {
  isHeadersAcceptWebp: bool,
  serverUserAgent: string,
  children: node,
};

export default DeviceInfoProvider;
