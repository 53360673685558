import { useLocaleContext } from 'components/shared/Locale';
import { useDeviceInfoContext } from 'components/shared/DeviceInfo';
import React from 'react';
import PropTypes from 'prop-types';
import { getImageSrc } from 'components/shared/LocaleImage/utils';
import Image from 'next/image';
import styles from './index.module.scss';
import mmsLoader from '../../../../tools/scripts/image/loader';
/*
  This component is used to get the image of selected language
  ---
  For example we have 'image.png' for EN, VI
  Step 1: copy 'image.png' for EN to 'public/img'
  Step 2: copy 'image.png' for VI to 'public/img/vi'
  Step 2: <LocaleImage src="image.png" />
  ---
  If 'image.png' is not found in 'public/img/vi', it will get `image.png` from 'public/img'
 */
const LocaleImage = (props) => {
  const { src } = props;
  const { language } = useLocaleContext();
  const { isAcceptWebp } = useDeviceInfoContext();

  if (!src) {
    return null;
  }

  return (
    <Image
      alt=""
      loader={(loaderProps) => mmsLoader({ isAcceptWebp, ...loaderProps })}
      {...props}
      src={getImageSrc(src, language)}
      layout="fill"
      loading="eager"
      quality={75}
      className={styles.localeImage}
    />
  );
};

LocaleImage.propTypes = {
  src: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default LocaleImage;
