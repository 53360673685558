import React, { createContext, useCallback, useContext } from 'react';
import { node, string } from 'prop-types';
import localeFunc from 'locale';

export const LocaleContext = createContext(null);
export const useLocaleContext = () => useContext(LocaleContext);

function LocaleProvider({ language, children }) {
  const locale = useCallback(
    (key, keyMap) => {
      return localeFunc(key, language, keyMap);
    },
    [language]
  );

  return (
    <LocaleContext.Provider value={{ locale, language }}>
      {children}
    </LocaleContext.Provider>
  );
}

LocaleProvider.propTypes = {
  language: string,
  children: node,
};

export default LocaleProvider;
