import React, { PureComponent } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import baseStyles from 'components/shared/Options/index.module.scss';

class Options extends PureComponent {
  optionWrapRef = React.createRef();

  headerRefs = {};

  componentDidMount() {
    document.addEventListener('keypress', this.handleKeyDown.bind(this));
  }

  componentDidUpdate(prevProps) {
    const { isOpen = true } = this.props;
    if (!prevProps.isOpen && isOpen) {
      if (this.optionWrapRef.current) {
        this.optionWrapRef.current.scrollTop = 0;
      }
    }
  }

  handleKeyDown(event) {
    const { countryCodeStyle = false } = this.props;
    if (countryCodeStyle) {
      const key = String.fromCharCode(event.keyCode).toUpperCase();
      if (/^[A-Z]$/.test(key)) {
        this.scrollToHeader(key);
      }
    }
  }

  scrollToHeader(key) {
    if (key in this.headerRefs && this.headerRefs[key].current) {
      this.optionWrapRef.current.scrollTop =
        this.headerRefs[key].current.offsetTop;
    }
  }

  renderOption({ value, option, prefix, header, key }, styles) {
    const { handleChange, countryCodeStyle = false } = this.props;

    if (countryCodeStyle) {
      if (header) {
        const ref = React.createRef();
        this.headerRefs[value] = ref;
        return (
          <div
            className={styles.countryCodeHeader}
            key={key || value}
            ref={ref}
          >
            <div className={styles.name}>{option}</div>
          </div>
        );
      }
      return (
        <a
          className={cx(styles.option, styles.countryCodeOption)}
          key={key || value}
          onClick={() => handleChange(value)}
        >
          <div className={styles.prefix}>{prefix}</div>
          <div className={styles.name}>{option}</div>
        </a>
      );
    }

    return (
      <a
        className={styles.option}
        key={key || value}
        onClick={() => handleChange(value)}
      >
        {option}
      </a>
    );
  }

  renderIndices() {
    return (
      <div className={baseStyles.indices}>
        {Object.keys(this.headerRefs).map((key) => (
          <div
            className={baseStyles.index}
            onClick={(event) => {
              this.scrollToHeader(key);
              event.stopPropagation();
            }}
            key={key}
          >
            {key}
          </div>
        ))}
      </div>
    );
  }

  render() {
    const {
      wrapperStyles,
      extraStyles,
      options,
      countryCodeStyle = false,
    } = this.props;

    if (!options || !options.length) {
      return null;
    }

    const styles = extraStyles ? { ...baseStyles, ...extraStyles } : baseStyles;

    return (
      <div
        className={`${styles.optionWrap} ${
          countryCodeStyle ? styles.optionWrapCountryCode : ''
        }`}
        style={wrapperStyles}
        ref={this.optionWrapRef}
      >
        <div className={styles.options}>
          {options.map((o) => this.renderOption(o, styles))}
        </div>
        {this.renderIndices()}
      </div>
    );
  }
}

Options.propTypes = {
  wrapperStyles: PropTypes.object,
  extraStyles: PropTypes.object,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      option: PropTypes.node.isRequired,
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.symbol,
      ]).isRequired,
    })
  ),
  handleChange: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
  countryCodeStyle: PropTypes.bool,
};

export default Options;
