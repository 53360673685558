var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"live-1730701540"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';
import {
  SENTRY_DSN,
  SENTRY_ENV,
  SENTRY_SAMPLE_RATE,
} from './sentry.base.config';

Sentry.init({
  dsn: SENTRY_DSN,
  environment: SENTRY_ENV,
  integrations: [
    // Increase idleTimeout to capture LCP (https://github.com/getsentry/sentry-javascript/issues/6742)
    Sentry.browserTracingIntegration({ idleTimeout: 2000 }),
  ],
  sampleRate: SENTRY_SAMPLE_RATE,
  tracesSampleRate: SENTRY_SAMPLE_RATE,
});
